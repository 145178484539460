var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-2" }, [
    _c(
      "div",
      { style: { width: !_vm.noInnerWidth ? _vm.width : "auto" } },
      [
        _c(
          "div",
          [
            _c("b-table", {
              attrs: {
                busy: _vm.isLoading,
                "head-variant": "light",
                "no-local-sorting": true,
                items: _vm.filteredItems,
                fields: _vm.getAllFields,
                "current-page": "1",
                "per-page": "0",
                "sticky-header": _vm.height,
                "no-border-collapse": "",
                responsive: "",
                "show-empty": "",
                "empty-text": "Non ci sono informazioni da mostrare",
                small: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "table-busy",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center my-2" },
                        [
                          _c("base-icon", {
                            attrs: {
                              name: "loading",
                              width: "35",
                              height: "35",
                            },
                          }),
                          _vm._v(" "),
                          _c("p", [_vm._v("Operazione in corso...")]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "cell(actions)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "action-buttons" },
                        [
                          _vm.actions.includes("edit")
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mt-1 mr-1",
                                  attrs: {
                                    size: "sm",
                                    variant: "lisaweb",
                                    title: "Modifica",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onEdit(row.item.id)
                                    },
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "pencil-square" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.actions.includes("destroy")
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mt-1 mr-1",
                                  attrs: {
                                    size: "sm",
                                    variant: "lisaweb",
                                    title: "Elimina",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDestroy(row.item.id)
                                    },
                                  },
                                },
                                [_c("b-icon", { attrs: { icon: "trash" } })],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-1 mr-1",
                              attrs: {
                                size: "sm",
                                variant: "lisaweb",
                                title:
                                  "\n                  Modifica Permessi Utente\n                ",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onEditUserPermissions(row.item)
                                },
                              },
                            },
                            [_c("b-icon", { attrs: { icon: "person-fill" } })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-1 mr-1",
                              attrs: {
                                size: "sm",
                                variant: "lisaweb",
                                title:
                                  "\n                  Modifica Permessi Produttori\n                ",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onEditBrokersPermissions(row.item)
                                },
                              },
                            },
                            [_c("b-icon", { attrs: { icon: "people-fill" } })],
                            1
                          ),
                          _vm._v(" "),
                          _vm.actions.includes("lisa_for_apps") && false
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mt-1 mr-1",
                                  attrs: {
                                    size: "sm",
                                    variant: "lisaweb",
                                    title:
                                      "\n                  Modifica Permessi LisaForApp\n                ",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onEditLisaForAppsPermissions(
                                        row.item
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "person-square" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.actions.includes("reports")
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mt-1 mr-1",
                                  attrs: {
                                    size: "sm",
                                    variant: "lisaweb",
                                    title:
                                      "\n                  Modifica Permessi Report\n                ",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onEditReportsPermissions(
                                        row.item
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "file-text" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(role)",
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            item.roles
                              ? item.roles.find((role) => role).name
                              : ""
                          ) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-modal",
          {
            attrs: {
              id: _vm.infomodalName,
              title: _vm.modalTitle,
              size: "xl",
              scrollable: "",
              "ok-only": "",
              "ok-variant": "lisaweb",
              "header-bg-variant": "lisaweb",
              "no-close-on-backdrop": "",
            },
            on: { hidden: _vm.onModalHidden },
          },
          [
            _c(
              "div",
              [
                _c(
                  "b-tabs",
                  {
                    attrs: { "content-class": "pt-1", lazy: "" },
                    model: {
                      value: _vm.tabIndex,
                      callback: function ($$v) {
                        _vm.tabIndex = $$v
                      },
                      expression: "tabIndex",
                    },
                  },
                  _vm._l(_vm.tabs, function (tab, index) {
                    return _c(
                      "b-tab",
                      {
                        key: index,
                        attrs: {
                          title: tab.text,
                          active: index === _vm.tabIndex,
                          "title-item-class": "lisaweb",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setTab(tab, index)
                          },
                        },
                      },
                      [
                        _c(
                          "keep-alive",
                          [
                            _vm.selected === tab.name
                              ? _c(_vm.selected, {
                                  tag: "component",
                                  attrs: { resourceId: _vm.modalResourceId },
                                  on: { fetch: _vm.fetch },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }