<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <!-- <div class="form-group col-md-6"></div>
      <div class="form-group col-md-3">
        <b-button
          :pressed.sync="pdfAll"
          variant="light"
          @click="toggleAllPermissions('pdf')"
          >{{ pdfAll ? "Deseleziona" : "Seleziona" }} tutti: PDF</b-button
        >
      </div>
      <div class="form-group col-md-3">
        <b-button
          :pressed.sync="csvAll"
          variant="light"
          class=""
          @click="toggleAllPermissions('csv')"
          >{{ csvAll ? "Deseleziona" : "Seleziona" }} tutti: CSV</b-button
        >
      </div> -->
      <b-col align="right" class="mr-4">
        <b-button
          :pressed.sync="pdfAll"
          variant="light"
          class="mr-1"
          @click="toggleAllPermissions('pdf')"
          >{{ pdfAll ? "Deseleziona" : "Seleziona" }} tutti: PDF</b-button
        >
        <b-button
          :pressed.sync="csvAll"
          variant="light"
          class=""
          @click="toggleAllPermissions('csv')"
          >{{ csvAll ? "Deseleziona" : "Seleziona" }} tutti: CSV</b-button
        >
      </b-col>
    </b-row>
    <reports
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :filterOn="{
        byRelations: ['byUser'],
      }"
      noActions
      noPagination
      :ref="tableRef"
    ></reports>
    <b-row>
      <b-col align="left">
        <b-button
          type="button"
          variant="lisaweb"
          size="sm"
          title="Salva Permessi"
          @click="onPermissions"
        >
          Salva Permessi
        </b-button>
        <b-button
          @click="$router.back()"
          variant="lisaweb"
          size="sm"
          class="float-right"
          ><b-icon-chevron-double-left
            font-scale="0.9"
          ></b-icon-chevron-double-left
          >Torna indietro</b-button
        >
      </b-col>
    </b-row>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import Reports from "@/components/tables/Reports.vue";
import { mapActions } from "vuex";
// import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";
import { computed } from "vue";

export default {
  mixins: [CustomFiltersMixin /* DetailTabAutofetchTableMixin */],
  data() {
    return {
      repository: "report",
      resource: "reports",
      tableRef: "ReportsPermissionTableRef",
      filterName: "filterReportsPermission",
      user: this.$route.params.user,
      args: {},
      pdfAll: false,
      csvAll: false,
      filter: null, //this.initFilter(),
      fields: [
        {
          key: "formatted_title",
          label: this.getDictionary("title", "report"),
          sortable: true,
          sortKey: "code",
          thStyle: { width: "70%" },
        },
        {
          key: "code",
          label: this.getDictionary("code", "report"),
          sortable: true,
          sortKey: "code",
          thStyle: { width: "20%" },
        },
        {
          key: "pdf",
          label: this.getDictionary("pdf", "report"),
          thStyle: { width: "5%" },
        },
        {
          key: "csv",
          label: this.getDictionary("csv", "report"),
          thStyle: { width: "5%" },
        },
      ],
    };
  },
  provide() {
    // use function syntax so that we can access `this`
    return {
      args: computed(() => this.args),
    };
  },
  components: {
    Reports,
  },
  methods: {
    initFilter() {
      let init = {
        byVoid: {
          value: null,
        },
      };
      return init;
    },
    onPermissions() {
      // "allowed": ["pdf", "csv"] == pdf Y csv Y
      // "allowed": ["pdf",] == pdf Y csv N
      // "allowed": ["csv"] == pdf N csv Y
      // "allowed": [] == pdf N csv N
      // const payload = {
      //   report: {},
      // };

      // this.$refs[this.tableRef].items.forEach((item) => {
      //   payload.report[item.id] = {
      //     pdf: item.pdf ? "Y" : "N",
      //     csv: item.csv ? "Y" : "N",
      //   };
      // });
      const payload = {
        report: {
          // 1: {
          //   allowed: ["pdf"],
          // },
        },
      };
      let ids = [];
      // this.$refs[this.tableRef].items.forEach((item) => {
      for (const item of this.$refs[this.tableRef].items) {
        ids.push(item.id);
        let arr = [];
        if (!item.pdf && !item.csv) {
          // non inviare allowed: [], e successivamente, se son tutti disabilitati, chiama pivot_destroy
          continue;
        }
        if (item.pdf) {
          arr.push("pdf");
        }
        if (item.csv) {
          arr.push("csv");
        }
        payload.report[item.id] = {
          allowed: arr,
        };
        // });
      }

      this.$refs[this.tableRef].isLoading = true;
      if (Object.keys(payload.report).length) {
        this.addReportPermission(this.user.id, this.repository, payload)
          .then(() => {
            this.$store.dispatch("auth/reports");
            this.$showSnackbar({
              preset: "info",
              text: "Permessi Aggiornati",
            });
            this.$refs[this.tableRef].isLoading = false;
            this.$refs[this.tableRef].fetch();
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
            this.$refs[this.tableRef].isLoading = false;
          });
      } else {
        // nessun report id (tutti disabilitati), chiamo la DELETE di pivot con payload "sbiancato"
        // {
        //     "report": {
        //         "1": {},
        //         "2": {},
        //         ...
        // }
        ids.forEach((id) => {
          payload.report[id] = {};
        });
        this.deleteReportPermission(this.user.id, this.repository, payload)
          .then(() => {
            this.$showSnackbar({
              preset: "info",
              text: "Permessi Rimossi",
            });
            this.$refs[this.tableRef].isLoading = false;
            this.$refs[this.tableRef].fetch();
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
            this.$refs[this.tableRef].isLoading = false;
          });
      }
    },
    addReportPermission(id, relation, payload) {
      const Repo = RepositoryFactory.get("user");
      return Repo.pivot_store(id, relation, payload);
    },
    deleteReportPermission(id, relation, payload) {
      const Repo = RepositoryFactory.get("user");
      return Repo.pivot_destroy(id, relation, payload);
    },
    setPermission(item, type) {
      if (!item.users?.length) {
        return false;
      }
      const found = item.users.find((e) => e.id === this.user.id);
      if (found) {
        // return found.pivot[type].value === "Y" ? true : false;
        return found.pivot.allowed.includes(type) ? true : false;
      }
      return false;
    },
    setPermissions(data) {
      data.forEach((e) => {
        e["pdf"] = this.setPermission(e, "pdf");
        e["csv"] = this.setPermission(e, "csv");
      });
      this.pdfAll = !data.some((e) => e.pdf === false);
      this.csvAll = !data.some((e) => e.csv === false);
      return data;
    },
    // selectAllPermissions(type) {
    //   this.$refs[this.tableRef].items.forEach((item) => {
    //     this.$set(item, type, type === "read" ? !this.readAll : !this.csvAll);
    //   });
    //   if (type === "read") {
    //     this.readAll = !this.readAll;
    //   }
    //   if (type === "write") {
    //     this.csvAll = !this.csvAll;
    //   }
    // },
    toggleAllPermissions(type) {
      this.$refs[this.tableRef].items.forEach((item) => {
        this.$set(item, type, type === "pdf" ? this.pdfAll : this.csvAll);
      });
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
  },
  created() {
    let criteria = this.filter;
    let name = this.filterName;
    this.saveFilterByName({ name, criteria });
    this.args = { items: { handle: this.setPermissions } };
  },
  mounted() {
    // set a default sort criteria
    this.$refs[this.tableRef].sortBy = "title";
    this.$refs[this.tableRef].sortDesc = false;
    // since there are no tabs, no need for DetailTabAutofetchTableMixin
    // and since filter is empty, automatic fetch won't trigger...
    this.$refs[this.tableRef].fetch();
  },
};
</script>
