<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="right" class="mr-4">
        <!-- <b-button
          type="button"
          variant="lisaweb"
          class="mb-2"
          size="sm"
          @click="selectAllPermissions('read')"
        >
          {{ readAll ? "Deseleziona" : "Seleziona" }} tutti i permessi di
          lettura</b-button
        > -->
        <b-button
          :pressed.sync="readAll"
          variant="light"
          class="mr-4"
          @click="toggleAllPermissions('read')"
          >{{ readAll ? "Deseleziona" : "Seleziona" }} tutti: Lettura</b-button
        >
        <!-- <b-button
          type="button"
          variant="lisaweb"
          class="mb-2"
          size="sm"
          @click="selectAllPermissions('write')"
        >
          {{ writeAll ? "Deseleziona" : "Seleziona" }} tutti i permessi di
          scrittura</b-button
        > -->
        <b-button
          :pressed.sync="writeAll"
          variant="light"
          class=""
          @click="toggleAllPermissions('write')"
          >{{ writeAll ? "Deseleziona" : "Seleziona" }} tutti:
          Modifica</b-button
        >
      </b-col>
    </b-row>
    <salesmen
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :filterOn="{
        byRelations: [/* 'byCorrespondence', 'byAddressBook',  */ 'byUser'],
      }"
      noActions
      noPagination
      :ref="tableRef"
    ></salesmen>
    <b-row>
      <b-col align="left">
        <b-button
          type="button"
          variant="lisaweb"
          size="sm"
          title="Salva Permessi"
          @click="onPermissions"
        >
          Salva Permessi
        </b-button>
        <b-button
          @click="$router.back()"
          variant="lisaweb"
          size="sm"
          class="float-right"
          ><b-icon-chevron-double-left
            font-scale="0.9"
          ></b-icon-chevron-double-left
          >Torna indietro</b-button
        >
      </b-col>
    </b-row>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import Salesmen from "@/components/tables/Salesmen.vue";
import { mapActions } from "vuex";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";
import { computed } from "vue";

export default {
  mixins: [CustomFiltersMixin, DetailTabAutofetchTableMixin],
  data() {
    return {
      repository: "broker",
      resource: "brokers",
      tableRef: "SalesmenPermissionTableRef",
      filterName: "filterSalesmenPermission",
      user: this.$route.params.user,
      args: {},
      readAll: false,
      writeAll: false,
      filter: this.initFilter(),
      fields: [
        {
          key: "attributables.BUID",
          label: this.getDictionary("attribute_BUID_value", "broker"),
          sortable: true,
          sortKey: "BUID",
        },
        {
          key: "attributables.SURN",
          label: this.getDictionary("attribute_SURN_value", "broker"),
          sortable: true,
          sortKey: "SURN",
        },
        {
          key: "attributables.NAME",
          label: this.getDictionary("attribute_NAME_value", "broker"),
          sortable: true,
          sortKey: "NAME",
        },
        {
          key: "attributables.CNAM",
          label: this.getDictionary("attribute_CNAM_value", "broker"),
          sortable: true,
          sortKey: "CNAM",
        },
        {
          key: "read",
          label: this.getDictionary("read", "broker"),
        },
        {
          key: "write",
          label: this.getDictionary("write", "broker"),
        },
      ],
    };
  },
  provide() {
    // use function syntax so that we can access `this`
    return {
      args: computed(() => this.args),
    };
  },
  components: {
    Salesmen,
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          status_broker: 0,
          title: null,
          NAME: null,
          SURN: null,
          BUID: null,
        },
      };
      return init;
    },
    onPermissions() {
      const payload = {
        broker: {},
      };

      this.$refs[this.tableRef].items.forEach((item) => {
        payload.broker[item.id] = {
          read: item.read ? "Y" : "N",
          write: item.write ? "Y" : "N",
        };
      });

      this.$refs[this.tableRef].isLoading = true;
      this.addBrokerPermission(this.user.id, this.repository, payload)
        .then(() => {
          this.$showSnackbar({
            preset: "info",
            text: "Permessi Aggiornati",
          });
          this.$refs[this.tableRef].isLoading = false;
          this.$refs[this.tableRef].fetch();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.$refs[this.tableRef].isLoading = false;
        });
    },
    addBrokerPermission(id, relation, payload) {
      const Repo = RepositoryFactory.get("user");
      return Repo.pivot_store(id, relation, payload);
    },
    setPermission(item, type) {
      if (!item.users?.length) {
        return false;
      }
      const found = item.users.find((e) => e.id === this.user.id);
      if (found) {
        return found.pivot[type].value === "Y" ? true : false;
      }
      return false;
    },
    setPermissions(data) {
      data.forEach((e) => {
        e["read"] = this.setPermission(e, "read");
        e["write"] = this.setPermission(e, "write");
      });
      this.readAll = !data.some((e) => e.read === false);
      this.writeAll = !data.some((e) => e.write === false);
      return data;
    },
    // selectAllPermissions(type) {
    //   this.$refs[this.tableRef].items.forEach((item) => {
    //     this.$set(item, type, type === "read" ? !this.readAll : !this.writeAll);
    //   });
    //   if (type === "read") {
    //     this.readAll = !this.readAll;
    //   }
    //   if (type === "write") {
    //     this.writeAll = !this.writeAll;
    //   }
    // },
    toggleAllPermissions(type) {
      this.$refs[this.tableRef].items.forEach((item) => {
        this.$set(item, type, type === "read" ? this.readAll : this.writeAll);
      });
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
  },
  created() {
    let criteria = this.filter;
    let name = this.filterName;
    this.saveFilterByName({ name, criteria });
    this.args = { items: { handle: this.setPermissions } };
  },
};
</script>
