var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canVerb(_vm.resource, "index")
    ? _c(
        "div",
        { staticClass: "mx-1" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { align: "right" } },
                [
                  !_vm.isLoadingCount && _vm.canVerb(_vm.resource, "store")
                    ? _c(
                        "b-button",
                        {
                          staticClass: "btn-create",
                          attrs: {
                            id: `popover-create`,
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            title: "Crea",
                          },
                          on: { click: _vm.onAdd },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "plus-circle",
                              "aria-hidden": "true",
                            },
                          }),
                          _vm._v("\n        Crea"),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mt-2" }, [
            _c(
              "div",
              [
                _c(
                  "b-overlay",
                  { attrs: { center: "", show: _vm.isLoading, rounded: "sm" } },
                  [
                    _c("users", {
                      ref: _vm.tableRef,
                      attrs: {
                        fields: _vm.fields,
                        repository: _vm.repository,
                        resource: _vm.resource,
                        filterName: _vm.filterName,
                        filterOn: {
                          byRelations: ["byRole"],
                        },
                        filteredItems: _vm.filteredItems,
                        onlyActions: [
                          "destroy",
                          "edit",
                          "lisa_for_apps",
                          "reports",
                        ],
                      },
                      on: {
                        edit: _vm.onEdit,
                        destroy: _vm.onDestroy,
                        user_permissions: _vm.onUpdateUserPermissions,
                        brokers_permissions: _vm.onUpdateBrokersPermissions,
                        lisa_for_apps_permissions:
                          _vm.onUpdateLisaForAppsPermissions,
                        reports_permissions: _vm.onUpdateReportsPermissions,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("b-icon", { attrs: { icon: "info-circle", scale: "1.00" } }),
          _vm._v("\n  Non hai i permessi\n"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }